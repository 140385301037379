
main > .container {
  /*padding: 60px 15px 0;*/
}

.footer {
  background-color: #f5f5f5;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

code {
  font-size: 80%;
}

/* components > Header.js */
.ag-header {
  background-color: white;
  height: 50px;
}

.ag-logo-link {
  /* margin-top: 20px; */
  /*margin-right: 0px;*/
  /* margin-bottom: 0px; */
  padding-left: 20px;
  float: 'left';

}

.ag-logo {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 40px;
}

.dx-menu-item-content .dx-icon {  
  float: right;  
}

.dx-menu-base .dx-menu-items-container {  
  padding-top: 10px;  
}  

.dx-header-row .header-vertical-align {
  vertical-align: middle!important;  
}

#main-menu-drawer {
  position: absolute;
  /* background-color: teal; */
  margin-top: 50px;
}

div#main-menu-drawer.dx-drawer-opened {
  z-index: 1500;
  transition: z-index 0s cubic-bezier(0,1,1,0);
}

div#main-menu-drawer:not(.dx-drawer-opened) {
  z-index: -1500;
  transition: opacity 2s, z-index 2s cubic-bezier(0,1,1,0);
}


#main-menu-list {
  /* padding-left: 5px; */
  /* padding-top: 5px; */
  background-color: #d9381e;
}

#main-menu-list .dx-list-item-content {
  /* border-bottom: 1px solid white; */
  color: white;
}

#main-menu-list .dx-list-group-header {
  /* border-bottom: 1px solid white; */
  color: #F4E6CC;
}

/* override the error messages to display under the widget text */
.dx-invalid-message .dx-overlay-wrapper {
  z-index: -1!important;
}

.dx-invalid-message .dx-overlay-wrapper .dx-overlay-content {
  padding-top: 30px!important;
  visibility: visible!important;
  z-index: -1!important;
}

.form-group-caption-line-break {
  white-space: pre-line;
}
